import * as React from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import BrandImagesWithReviews from "components/BrandImagesWithReviews"
import Reference from "components/ReferencesSection/Reference"
import Accordion from "components/Accordion"
import ArticlesForm from "components/Form/ArticlesForm"
import Media from "../Media"
import Container from "../container"
import Button, { ButtonBase } from "../button"
import { SpanH2, P } from "../headings"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import Card from "./Card/Card"
import { ISectionProps, IThemeValue, ITitleSizeValue } from "./section.model"
import { getColorScheme, theme as themeCommon } from "../../common/colorScheme"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import { useSectionTheme } from "./SectionThemeContext"
import { elementData } from "./utils"
import BackgroundAnimation from "../BackgroundAnimation"
import { AdditionalText } from "./ImageRight"

const textAndMediaContainerStyles = css`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

const subTitleStyles = css`
	font-size: 2rem;
	line-height: 2.8rem;
	margin-bottom: ${themeCommon.spacing(2)};
`

const noPadding = css`
	padding: 0;
`

const containerStyles = css`
	padding-bottom: 0px;
	position: relative;

	${mediaBreakpoint(BreakPoints.SM)} {
		box-shadow: none;
		position: initial;
	}
`

const secondaryImageStyles = css`
	margin-bottom: ${themeCommon.spacing(7.5)};
	max-width: 100%;
`

const backgroundGradientWrapper = css`
	height: 100%;
	width: 100%;
	position: relative;
`

const hideOverflow = css`
	top: 0;
	position: absolute;
	height: 100%;
	width: 100%;
	overflow: hidden;
`

const hideOnMobile = css`
	visibility: hidden;

	${mediaBreakpoint(BreakPoints.LG)} {
		visibility: visible;
	}
`

const coverStyles = css`
	display: flex;
	justify-content: center;
	content-visibility: initial;
	align-items: center;
	flex-direction: column;
	z-index: 1;
	position: relative;
`

const getBannerTextContainerStyles = (titleSize: ITitleSizeValue) => css`
	text-align: left;
	margin: 0 0 1.6rem;
	padding: 54px 10px 0;
	align-self: center;
	${mediaBreakpoint(BreakPoints.SM)} {
		text-align: center;
		margin: 0 0 64px;
		width: 70%;
		margin: 0;
		padding: ${titleSize && titleSize === "large" ? "118px 2rem 0" : "0 2rem"};
		white-space: break-spaces;
	}
	${mediaBreakpoint(BreakPoints.MD)} {
		width: 50%;
	}

	&:empty {
		display: none;
	}
`

const bannerMediaStyles = css`
	width: 100%;
	max-width: 100%;
	${mediaBreakpoint(BreakPoints.SM)} {
		max-width: 66%;
		width: 66%;
	}
`

const buttonsContainerStyles = css`
	margin: 32px 0 0;
	&:empty {
		display: none;
	}
	${ButtonBase} {
		width: 100%;
		margin: 0 0 12px;
		${mediaBreakpoint(BreakPoints.SM)} {
			width: initial;
			margin: 0;
		}
	}
`
const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0rem;
	${mediaBreakpoint(BreakPoints.SM)} {
		margin-bottom: 8rem;
	}
	&:empty {
		display: none;
	}
`

const cardsContainerStyles = css`
	padding-top: 0;
	padding-bottom: 0rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	&:empty {
		display: none;
	}
	${mediaBreakpoint(BreakPoints.MD)} {
		margin-top: 2rem;
		flex-direction: row;
		justify-content: center;
	}
`

const cardsScrollableContainerStyles = css`
	overflow: scroll;
	padding-left: 30px;
	scroll-snap-type: x mandatory;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}

	${mediaBreakpoint(BreakPoints.SM)} {
		padding-top: 50px;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		overflow: initial;
	}
`

const scrollContainerStyles = css`
	display: flex;
	width: fit-content;
	margin-bottom: 60px;

	${mediaBreakpoint(BreakPoints.LG)} {
		width: 100%;
	}
`

const singleCardStyles = css`
	width: 100%;

	&:last-of-type {
		padding-bottom: ${themeCommon.spacing(6)};

		${mediaBreakpoint(BreakPoints.SM)} {
			padding-bottom: 0;
		}
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		max-width: 340px;
	}
`

const singleCardContainerStyle = css`
	${mediaBreakpoint(BreakPoints.MD)} {
		flex-basis: calc(33% - 60px);
		max-width: calc(33% - 60px);

		[data-element="card-container"] {
			margin: 0;
		}
	}
`

const singleScrollableCardContainerStyle = css`
	${mediaBreakpoint(BreakPoints.MD)} {
		flex-basis: 33%;
		max-width: 33%;
	}
`

const signleScrollableCardStyle = css`
	margin-right: 4vw;
	padding: 20px 6vw;
	scroll-snap-align: center;
	transition: box-shadow 0.2s ease-in-out;

	${mediaBreakpoint(BreakPoints.SM)} {
		margin-right: 10px;
		padding: 30px 12vw;
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		margin-right: 0;
		padding: 30px 40px;
	}
`

const buttonStyles = css`
	width: 100%;
	margin-top: 1.6rem;
	margin-bottom: 1.6rem;
	${mediaBreakpoint(BreakPoints.SM)} {
		width: initial;
	}
`

const headingStyles = css`
	margin: 0;
`

const descriptionStyles = css`
	margin: ${themeCommon.spacing(3, 0, 2)};
`

const ReferencesContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: ${themeCommon.spacing(5, 0)};

	${mediaBreakpoint(BreakPoints.MD)} {
		flex-direction: row;
	}
`

const getThemeStyles = (theme: IThemeValue) => {
	switch (theme) {
	case "white-text":
		return css`
				color: white;
			`
	default:
		return css`
				color: var(--text-color);
			`
	}
}

const BannerCenterNew: React.FC<ISectionProps> = ({
	buttons,
	cards,
	colorScheme,
	backgroundGradientsBottomLeft,
	backgroundGradientsBottomRight,
	backgroundGradientsTopLeft,
	backgroundGradientsTopRight,
	backgroundGradientsWidth = 50,
	description,
	media,
	title,
	titleLevel,
	children,
	customStyle,
	anchor,
	scrollCardsOnMobile,
	brandImages,
	brandImagesScrolling,
	secondaryImage,
	subTitle,
	additionalText,
	pageReferences,
	accordionComponent,
	newsletterFormPurple
}) => {
	const titleSize = useTitleSize()
	const theme = useSectionTheme()

	const hasCards = cards?.length > 0
	const hasButtons = buttons?.length > 0
	const hasReferences = pageReferences?.length > 0
	const backgroundGradients =
		backgroundGradientsBottomLeft ||
		backgroundGradientsBottomRight ||
		backgroundGradientsTopLeft ||
		backgroundGradientsTopRight

	return (
		<section
			id={anchor}
			css={[customStyle, getThemeStyles(theme), getColorScheme(colorScheme)]}
			data-element={elementData.section}
		>
			<div css={backgroundGradientWrapper} data-element={elementData.gradientWrapper}>
				{backgroundGradients && (
					<div data-element="hidden" css={[hideOverflow, hideOnMobile]}>
						<Container
							customStyles={css`
								position: relative;
								height: calc(100% - 202px);
								margin-top: 202px;
								transform: translateX(${100 - backgroundGradientsWidth}%);
							`}
						>
							<BackgroundAnimation
								width={backgroundGradientsWidth}
								backgroundGradientsBottomLeft={backgroundGradientsBottomLeft}
								backgroundGradientsBottomRight={backgroundGradientsBottomRight}
								backgroundGradientsTopLeft={backgroundGradientsTopLeft}
								backgroundGradientsTopRight={backgroundGradientsTopRight}
							/>
						</Container>
					</div>
				)}
				<div css={coverStyles} data-element={elementData.cover}>
					<Container css={[containerStyles, scrollCardsOnMobile && noPadding]} data-element={elementData.container}>
						<div css={textAndMediaContainerStyles} data-element={elementData.textAndMediaContainer}>
							{secondaryImage && (
								<div css={secondaryImageStyles}>
									<Media {...secondaryImage} />
								</div>
							)}
							<div css={getBannerTextContainerStyles(titleSize)} data-element={elementData.bannerTextContainer}>
								{subTitle && <div data-element="subtitle" css={subTitleStyles}>{subTitle}</div>}
								{title ? (
									<SpanH2
										css={[getSizeStyles(titleSize), headingStyles]}
										sectionTheme={theme}
										as={titleLevel || "h2"}
										data-element="title"
									>
										{title}
									</SpanH2>
								) : null}
								{description && description.length ? (
									<P sectionTheme={theme} css={descriptionStyles}>
										{description}
									</P>
								) : null}
								{hasButtons && !hasCards ? (
									<div css={buttonsContainerStyles}>
										{(buttons || []).map(button => {
											const { id } = button
											return (
												<div key={id} css={buttonStyles}>
													<Button {...button} />
												</div>
											)
										})}
									</div>
								) : null}
							</div>
							{media && (
								<div css={bannerMediaStyles}>
									<Media {...media} />
								</div>
							)}
						</div>

						{hasReferences && (
							<ReferencesContainer>
								{pageReferences.map(reference => {
									const { id, filterCategories } = reference
									return <Reference 
										tags={filterCategories}
										key={id} 										
										{...reference} />									
								})}
							</ReferencesContainer>
						)}

						{hasCards && !scrollCardsOnMobile && (
							<div data-element="cards-container"css={cardsContainerStyles}>
								{(cards || []).map(({ id, ...rest }) => (
									<Card
										key={id}
										id={id}
										cardCss={singleCardStyles}
										cardContainerCss={singleCardContainerStyle}
										{...rest}
									/>
								))}
							</div>
						)}
						{hasCards && scrollCardsOnMobile && (
							<div css={cardsScrollableContainerStyles}>
								<div css={scrollContainerStyles}>
									{(cards || []).map(({ id, ...rest }) => (
										<Card
											key={id}
											id={id}
											cardCss={signleScrollableCardStyle}
											cardContainerCss={singleScrollableCardContainerStyle}
											{...rest}
										/>
									))}
								</div>
							</div>
						)}

						{hasCards && hasButtons && (
							<ButtonContainer data-element={elementData.buttonContainer}>
								{(buttons || []).map(button => {
									const { id } = button
									return (
										<div key={id} css={buttonStyles}>
											<Button {...button} />
										</div>
									)
								})}
							</ButtonContainer>
						)}

						{children}

						{additionalText && (
							<Container data-element="additional-text-container">
								<AdditionalText
									sectionTheme={theme}
									dangerouslySetInnerHTML={{ __html: additionalText?.childMarkdownRemark.html }}
								/>
							</Container>
						)}
					</Container>
					{brandImages && brandImagesScrolling && (
						<BrandImagesWithReviews compact transparent images={brandImages.logos} />
					)}
					{newsletterFormPurple ? <ArticlesForm {...newsletterFormPurple}/> : null}
					{accordionComponent ? (<Accordion />) : null }
				</div>
			</div>
		</section>
	)
}

export default React.memo(BannerCenterNew)
